<template>
	<div>
    <div class="title">申请卡片</div>
    <van-field
      v-model="name"
      type="text"
      label="姓名"
      :label-width="100"
    />
    <van-field
      v-model="mobile"
      type="text"
      label="手机号"
      :label-width="100"
      disabled
    />
		<van-field
			:value="currency"
			is-link
			readonly
			label="币种"
			placeholder="请选择币种"
			@click="show = true"
		/>
		<van-popup v-model="show" round position="bottom">
			<van-picker
				:columns="list"
				@cancel="show = false"
				@confirm="onConfirm"
				show-toolbar
			/>
		</van-popup>
    <van-field
      v-model="card_name"
      type="text"
      label="卡片"
      :label-width="100"
      disabled
    />
    <van-field
      v-model="address"
      type="textarea"
      label="地址"
      :label-width="100"
    />
    
    <div class="common_block">
      <van-button type="warning" block @click="postPapers">{{translate('next_step')}}</van-button>
    </div>
	</div>
</template>

<script>
	export default {
		name: 'personal_step4',
		data() {
			return {
				ispost:true,
				name:'',
        mobile:'',
        currency:'',
        card_name:'',
        address:'',
				show:false,
				list:['USD']
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
			this.getInit()
		},
		methods: {
			onConfirm(value){
				this.currency = value
				this.show = false
			},
      getInit(){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/applyInit',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
            this.name = res.data.name
            this.mobile = res.data.mobile
            this.currency = res.data.currency
            this.card_name = res.data.card_name
            this.address = res.data.address
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			postPapers(){

				if (this.name == "") {
					this.$dialog.alert({
						message: '请输入姓名'
					})
					return
				}
				if (this.currency == "") {
					this.$dialog.alert({
						message: '请选择币种'
					})
					return
				}
				if (this.address == "") {
					this.$dialog.alert({
						message: '请输入地址'
					})
					return
				}
				if(!this.ispost){
					return false;
				}
				this.ispost=false;
				this.$axios({
					method: 'post',
					url: 'speedpay_card/apply',
					data: {
						token:this.$route.query.token,
						name:this.name,
						currency:this.currency,
						card_name:this.card_name,
						address:this.address
					}
				}).then ((res) => {
					if (res.success) {
						// this.$dialog.alert({
						// 	message: '开卡成功'
						// })
            this.$router.replace('/speedpay/step5?token='+this.$route.query.token)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
						setTimeout(()=>{
							this.$router.replace('/speedpay/index?token='+this.$route.query.token)
						},2000)
					}
				})
			}
		},
	}
</script>
<style scoped>
  .title{
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
    text-align: center;
    background: #fff;
    margin-bottom: 10px;
  }
</style>
